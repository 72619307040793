<template>
  <div class="w-100">
    <div class="float-label mt-3">
      <input
        type="Number"
        :class="[validationClass, textClass, { 'border-0': borderInactive }]"
        v-bind="$attrs"
        v-model="inputValue"
        :min="min"
        :max="max"
        step="any"
        :placeholder="inputText"
        :aria-label="inputText"
      />
      <label class="label w-100" :class="labelClass">{{ inputText }}</label>
      <div class="icon-container d-flex" v-if="iconActive">
        <div @click="plus" class="cursor-pointer">
          <base-icon name="plus" color="#007bff" class="ml-1" width="18" height="18" />
        </div>
        <div @click="moins" class="cursor-pointer">
          <base-icon name="minus" color="#007bff" class="ml-1" width="18" height="18" />
        </div>
      </div>
    </div>
    <div class="invalid-feedback d-block list-unstyled" v-if="errors">
      <li v-for="error in errors" :key="error">{{ error }}</li>
    </div>
  </div>
</template>
<script>
import BaseIcon from "@/components/bases/Icon.vue";
export default {
  name: "BaseInputNumber",
  components: { BaseIcon },
  inheritAttrs: false,
  props: {
    inputText: {
      type: String,
      default: "label"
    },
    inputType: {
      type: String,
      default: "text"
    },
    value: {
      type: [String, Number],
      default: 0
    },
    errors: {
      type: [Object, Array, String, Number],
      default: () => {
        return {};
      }
    },
    iconActive: {
      type: Boolean,
      default: true
    },
    borderInactive: {
      type: Boolean,
      default: false
    },
    darkBackgroundMode: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number,
      required: false
    },
    max: {
      type: Number,
      required: false
    },
    step: {
      type: Number,
      default: 1
    }
  },

  methods: {
    plus: function() {
      this.$emit("input", parseFloat(this.value) + 1);
    },

    moins: function() {
      this.$emit("input", parseFloat(this.value) - 1);
    }
  },

  computed: {
    validationClass: function() {
      if (this.errors) {
        return "invalid";
      } else {
        return "valid";
      }
    },

    textClass: function() {
      if (!this.$store.getters["colors/darkMode"] && !this.darkBackgroundMode) {
        return "text-dark";
      } else {
        return "text-white";
      }
    },

    labelClass: function() {
      if (!this.$store.getters["colors/darkMode"] && !this.darkBackgroundMode) {
        return "label-dark";
      } else {
        return "label-white";
      }
    },
    inputValue: {
      get() {
        if (this.value == undefined) this.$emit("input", 0);
        return this.value;
      },
      set(e) {
        this.$emit("input", parseFloat(e));
      }
    }
  }
};
</script>
<style>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -moz-appearance: none;
  -webkit-appearance: none;
  margin: 0;
}
</style>
