<template>
  <div class="rounded">
    <div class="d-flex align-items-center">
      <div v-if="files[0] && !loading" class="m-0 cursor-pointer text-primary" @click="downloadDocument(files[0])">
        {{ title }}
      </div>
      <div v-if="loading" class="text-muted">Chargement...</div>
      <div class="ml-auto d-flex flex-row pl-3">
        <label class="m-0" :for="id">
          <button-circle name="file-plus" size="16" v-tooltip="'Ajouter un modèle'"></button-circle>
        </label>
      </div>
    </div>
    <!-- DOC UPLOADER -->
    <input :id="id" type="file" @change="addFileToUpload" class="d-none" multiple :accept="file_accepted" />
  </div>
</template>
<script>
import ButtonCircle from "@/components/bases/ButtonCircle.vue";

export default {
  name: "Uploader",
  components: { ButtonCircle },
  data: function () {
    return {
      files: [],
      filesToUpload: [],
      loading: false,
      progress: 0,
      rows: true,
      errors: [],
    };
  },
  props: {
    id: {
      type: String,
      default: "document",
    },
    url: {
      type: String,
      default: "",
    },
    file_system: {
      type: String,
      default: "",
    },
    file_accepted: {
      type: String,
      default: ".doc, .docx, .xls, .xlsx, .xlsm",
    },
    title: {
      type: String,
      default: "Liste des modèles",
    },
    data: {
      type: Object,
      required: false,
    },
    shadow: {
      type: Boolean,
      default: true,
    },
  },
  computed: {},
  mounted() {
    this.getDocuments();
  },
  methods: {
    getDocuments: function () {
      this.loading = true;
      this.$http
        .get("/trames", {
          params: {
            file_system: this.file_system,
          },
        })
        .then((response) => {
          if (response.data && response.data.length > 0) this.files = [response.data[response.data.length - 1]];
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    },

    downloadDocument: function (file) {
      let file_name = file.file_name + "." + file.file_extension;
      this.$http({
        method: "post",
        url: "/trames",
        data: {
          id: file.id,
          data: this.data,
        },
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file_name);
        document.body.appendChild(link);
        link.click();
      });
      //.finally(() => {});
    },

    deleteDocument: function (file) {
      file.loadingDelete = true;
      this.$http
        .delete("/trames/" + file.id)
        .then(() => {
          const i = this.files.indexOf(file);
          this.files.splice(i, 1);
        })
        .catch((error) => console.log(error));
      //.finally(() => (file.loadingDelete = false));
    },

    addFileToUpload: function (e) {
      let _files = e.target.files || e.dataTransfer.files;
      for (let i = 0; i < _files.length; i++) {
        this.filesToUpload.push({
          file: _files[i],
          name: _files[i].name,
          size: _files[i].size,
          type: _files[i].type,
          progress: 0,
          state: "en attente",
          error: "",
        });
      }
      this.upload();
    },
    upload: async function () {
      this.errors = [];
      for (let i = 0; i < this.filesToUpload.length; i++) {
        let formData = new FormData();
        this.filesToUpload[i].state = "en cours de chargement";
        formData.append("file", this.filesToUpload[i].file);
        formData.append("file_system", this.file_system);
        //formData.append("file_name", this.file_name);
        await this.$http
          .post("/upload/trame", formData, {
            onUploadProgress: (progressEvent) => {
              if (progressEvent.lengthComputable) {
                this.filesToUpload[i].progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
              }
            },
          })
          .then(() => {
            this.filesToUpload[i].state = "Terminé";
            this.filesToUpload[i].progress = 0;
          })
          .catch((error) => this.errors.push(error.response.data.error.file[0]))
          .finally(() => {});
      }
      this.getDocuments();
      this.filesToUpload = [];
    },
  },
};
</script>
<style>
.border-toolbar {
  border-bottom: 1px solid rgb(1, 1, 1, 0.05);
}

.hover-file:hover {
  background-color: rgb(1, 1, 1, 0.03) !important;
}
</style>
