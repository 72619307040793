<template>
  <div class="d-flex flex-column flex-grow-1 rounded">
    <BaseToolBar title="Formations en ligne" subtitle="Liste des inscriptions disponibles"></BaseToolBar>
    <div class="mb-4">
      <div v-for="frm in data.moocFormations" :key="frm.id" class="d-flex align-items-center">
        <button-circle
          v-if="!inArray(frm.id)"
          class="m-0 mr-2"
          :loading="loading"
          @click="assignMooc(frm.id)"
          name="circle"
          size="18"
        ></button-circle>
        <button-circle
          v-if="inArray(frm.id)"
          class="m-0 mr-2"
          :loading="loading"
          @click="revokeMooc(frm.id)"
          name="check-circle"
          size="18"
        ></button-circle>
        <div>
          <span>{{ frm.description }}</span>
          <span class="ml-1" v-if="inArray(frm.id) && getFormationRealisee(frm.id).realisee"> - Réalisée </span>
          <span class="ml-1" v-if="inArray(frm.id) && !getFormationRealisee(frm.id).realisee"> - Non réalisée </span>
          <span class="ml-1" v-if="inArray(frm.id) && getFormationRealisee(frm.id).poucentage_reussite">
            - {{ getFormationRealisee(frm.id).poucentage_reussite }}%</span
          >
        </div>
        <trame-uploader-only-one
          v-if="
            getFormationRealisee(frm.id) &&
            getFormationRealisee(frm.id).poucentage_reussite &&
            getFormationRealisee(frm.id).poucentage_reussite > 70
          "
          :id="'document_divers_trames' + frm.id"
          :file_system="'MOOC'"
          title="Exportez l'attestation"
          :data="exportAttestation(frm)"
          class="pl-3"
        ></trame-uploader-only-one>
      </div>
    </div>

    <BaseToolBar title="Formations" subtitle="Liste des formations disponibles">
      <BaseButton
        class="btn btn-primary ml-3"
        v-tooltip="'Ajouter une formation'"
        @click="storeFormation"
        :loading="loading"
        icon="plus"
        text="Ajouter"
      ></BaseButton>
    </BaseToolBar>
    <div class="d-flex flex-column flex-md-row w-100 mb-4">
      <div class="flex-grow-1 d-flex flex-column">
        <div v-for="formation in formations" :key="formation.id">
          <formations-item :formation="formation" v-if="formation.formation_active === null"></formations-item>
        </div>
        <div v-if="formations.length > 0" class="mb-3">
          <span
            @click="showActives = true"
            :class="{ 'text-muted': !showActives, 'text-primary': showActives }"
            class="mr-3 cursor-pointer"
            >{{ formationActiveCount }} formations actives</span
          >
          <span
            @click="showActives = false"
            :class="{ 'text-muted': showActives, 'text-primary': !showActives }"
            class="mr-2 cursor-pointer"
            >{{ formationinActiveCount }} formations inactives</span
          >
        </div>
        <div v-if="showActives">
          <div v-for="formation in formations" :key="'actives_' + formation.id">
            <formations-item :formation="formation" v-if="formation.formation_active === true"></formations-item>
          </div>
        </div>
        <div v-if="!showActives">
          <div v-for="formation in formations" :key="'inactives_' + formation.id">
            <formations-item :formation="formation" v-if="formation.formation_active === false"></formations-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapMultiRowFields } from "vuex-map-fields";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

import FormationsItem from "@/components/collaborateurs/profil/forms/formation/FormationsItem.vue";
import BaseToolBar from "@/components/bases/ToolBar.vue";
import ButtonCircle from "@/components/bases/ButtonCircle.vue";
import BaseButton from "@/components/bases/Button.vue";
import TrameUploaderOnlyOne from "@/components/application/TrameUploaderOnlyOne.vue";

export default {
  components: {
    FormationsItem,
    BaseToolBar,
    ButtonCircle,
    BaseButton,
    TrameUploaderOnlyOne,
  },
  data() {
    return {
      newFormation: { formation_collaborateur_id: 0 },
      feedback: {},
      showActives: true,
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      _storeFormation: "collaborateurs/createFormation",
      _assignMooc: "collaborateurs/assignMooc",
      _revokeMooc: "collaborateurs/revokeMooc",
    }),
    storeFormation() {
      this.feedback = {};
      this.loading = true;
      this._storeFormation(this.newFormation)
        //.catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    assignMooc(formation_id) {
      this.feedback = {};
      this.loading = true;
      this._assignMooc({
        formation_id: formation_id,
        collaborateur_id: this.id,
      })
        //.catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    revokeMooc(formation_id) {
      this.feedback = {};
      this.loading = true;
      this._revokeMooc({
        formation_id: formation_id,
        collaborateur_id: this.id,
      })
        //.catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    inArray: function (id) {
      return this.mooc.some((d) => d.formation_id == id);
    },
    getFormationRealisee(id) {
      const index = this.mooc.findIndex((x) => x.formation_id === id);
      return this.mooc[index];
    },
    exportAttestation(frm) {
      if (this.getFormationRealisee(frm.id) && this.getFormationRealisee(frm.id).system_update_time)
        return {
          values: {
            collaborateur: this.collaborateur_nom + " " + this.collaborateur_prenom,
            formation: frm.description,
            reference: this.getFormationRealisee(frm.id).id,
            date: this.$moment(this.getFormationRealisee(frm.id).system_update_time).format("DD-MM-YYYY"),
            validite: this.$moment(this.getFormationRealisee(frm.id).system_update_time)
              .add(36, "months")
              .format("DD-MM-YYYY"),
            resume: frm.commentaire,
          },
        };
    },
  },
  mounted() {
    this.newFormation.formation_collaborateur_id = this.id;
  },
  computed: {
    ...mapFields("collaborateurs", [
      "collaborateur.id",
      "collaborateur.collaborateur_nom",
      "collaborateur.collaborateur_prenom",
    ]),
    ...mapMultiRowFields("collaborateurs", ["formations", "mooc"]),
    ...mapGetters({
      data: "collaborateurs/data",
    }),
    formationActiveCount() {
      return this.formations.filter((formation) => formation.formation_active === true).length;
    },
    formationinActiveCount() {
      return this.formations.filter((formation) => formation.formation_active === false).length;
    },
  },
};
</script>
<style lang="css"></style>
