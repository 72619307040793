<template>
  <container-list-extend title="Formation">
    <template v-slot:title>
      <div class="p-1 rounded-top d-flex flex-row align-items-center flex-grow-1">
        <BaseIcon
          name="circle"
          width="10"
          height="10"
          class="float-right text-success"
          fill="currentColor"
          v-if="formation.formation_active"
          v-tooltip="'Formation active'"
        ></BaseIcon>
        <BaseIcon
          name="circle"
          width="10"
          height="10"
          class="float-right text-danger"
          fill="currentColor"
          v-if="!formation.formation_active"
          v-tooltip="'Formation inactive'"
        ></BaseIcon>
        <h6 class="m-0 ml-3" style="line-height: 1rem">
          <description-from-list
            :value="formation.formation_libelle_id"
            :data="data.formationLibelles"
            text="Nouvelle formation"
          ></description-from-list>
        </h6>
      </div>
      <div class="text-muted pl-2 pr-2 pl-md-3 pr-md-3 d-flex align-items-center">
        <small>
          <div v-if="formation.formation_date_validite">
            validité :
            {{ $moment(formation.formation_date_validite).format("DD/MM/YYYY") }}
          </div>
        </small>
      </div>
    </template>
    <template v-slot:form>
      <base-form
        @click="updateFormation(formation)"
        deleteBtn
        @delete="deleteFormation(formation)"
        :statut="statut"
        :loading="loading"
      >
        <base-form-row row>
          <div class="d-flex align-items-center w-100">
            <formations-libelle-manager
              class="w-100"
              inputText="Formation"
              v-model.number="formation.formation_libelle_id"
              :data="data.formationLibelles"
              :errors="feedback.formation_libelle_id"
            ></formations-libelle-manager>
          </div>
          <base-select
            inputText="Etat"
            v-model.number="formation.formation_etat_id"
            :options="data.formationEtats"
            :errors="feedback.formation_etat_id"
          ></base-select>
        </base-form-row>
        <base-form-row row>
          <base-select
            inputText="Organisme"
            v-model.number="formation.formation_organisme_id"
            :options="data.formationOrganismes"
            :errors="feedback.formation_organisme_id"
          ></base-select>
          <base-select
            inputText="Type"
            v-model.number="formation.formation_type_id"
            :options="data.formationTypes"
            :errors="feedback.formation_type_id"
          ></base-select>
        </base-form-row>
        <base-form-row row>
          <base-inputDatePicker
            v-model="formation.formation_date_debut"
            :errors="feedback.formation_date_debut"
            inputText="Date de début"
          ></base-inputDatePicker>
          <base-inputDatePicker
            v-model="formation.formation_date_fin"
            :errors="feedback.formation_date_fin"
            inputText="Date de fin"
          ></base-inputDatePicker>
        </base-form-row>
        <base-form-row row>
          <base-inputDatePicker
            v-model="formation.formation_date_validite"
            inputText="Date de validité"
            :errors="feedback.formation_date_validite"
          ></base-inputDatePicker>
        </base-form-row>
        <base-form-row row>
          <base-input
            v-model="formation.formation_attestation"
            inputText="N° Attestation"
            :errors="feedback.formation_attestation"
          ></base-input>
          <div class="w-100">
            <Checkbox
              class="w-100 pt-1"
              v-model="formation.formation_avec_arkadia"
              text="Formation réalisée avec Arkadia"
              :errors="feedback.formation_avec_arkadia"
              :iconActive="true"
            ></Checkbox>
            <Checkbox
              class="w-100 pt-1"
              v-model="formation.formation_obligatoire"
              text="Formation obligatoire"
              :errors="feedback.formation_obligatoire"
              :iconActive="true"
            ></Checkbox>
          </div>
        </base-form-row>
        <base-form-row row>
          <base-select
            inputText="Société pour la facturation"
            v-model.number="formation.formation_societe_id"
            :options="data.societes"
            :errors="feedback.formation_societe_id"
            :disabled="false"
          ></base-select>
          <base-input-number
            v-model="formation.formation_nbre_heures"
            inputText="Nombre d'heures"
            :errors="feedback.formation_nbre_heures"
          ></base-input-number>
        </base-form-row>
        <base-form-row row>
          <base-input
            v-model="formation.formation_facture_ref"
            inputText="N° Facture"
            :errors="feedback.formation_facture_ref"
          ></base-input>
        </base-form-row>
        <base-form-row row>
          <base-input-number
            v-model="formation.formation_montant_arkadia"
            inputText="Montant formation"
            :errors="feedback.formation_montant_arkadia"
          ></base-input-number>
          <base-input-number
            v-model="formation.formation_montant_opca"
            inputText="Montant pris en charge OPCO"
            :errors="feedback.formation_montant_opca"
          ></base-input-number>
        </base-form-row>
        <base-form-row row v-if="formation.formation_montant_arkadia > 0 && formation.formation_montant_opca > 0">
          <base-select
            inputText="Dispositif OPCO"
            v-model.number="formation.formation_opco_id"
            :options="data.formationOpco"
            :errors="feedback.formation_opco_id"
            :disabled="false"
          ></base-select>
          <div class="w-100 border rounded p-3">
            <span class="text-primary">Montant Arkadia :</span>
            {{ calculMontantFormation }}€
          </div>
        </base-form-row>
        <base-form-row row>
          <base-input-area
            v-model="formation.formation_commentaire"
            text="Commentaire"
            :errors="feedback.formation_commentaire"
          ></base-input-area>
        </base-form-row>
        <div class>
          <uploader
            :id="'document_formation_' + formation.id"
            url="/upload/document"
            file_system="SIRH"
            :file_field="'formations_' + formation.id"
            :file_key="$route.params.id"
          ></uploader>
        </div>
      </base-form>
    </template>
  </container-list-extend>
</template>
<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { errHandler } from "@/helpers.js";

import DescriptionFromList from "@/components/bases/DescriptionFromList";
import BaseIcon from "@/components/bases/Icon.vue";
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInput from "@/components/bases/Input.vue";
import BaseInputNumber from "@/components/bases/InputNumber.vue";
import BaseSelect from "@/components/bases/Select.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import Uploader from "@/components/bases/Uploader.vue";
import Checkbox from "@/components/bases/Checkbox.vue";

import FormationsLibelleManager from "@/components/collaborateurs/profil/forms/formation/FormationsLibelleManager.vue";

export default {
  name: "FormationsItem",
  components: {
    BaseIcon,
    ContainerListExtend,
    BaseForm,
    BaseFormRow,
    BaseInput,
    BaseInputNumber,
    BaseSelect,
    BaseInputDatePicker,
    Uploader,
    DescriptionFromList,
    BaseInputArea,
    FormationsLibelleManager,
    Checkbox,
  },
  data() {
    return {
      feedback: {},
      loading: false,
      statut: null,
    };
  },
  props: ["formation"],
  computed: {
    ...mapGetters({
      data: "collaborateurs/data",
    }),
    calculMontantFormation() {
      return this.formation.formation_montant_arkadia - this.formation.formation_montant_opca;
    },
  },
  methods: {
    ...mapActions({
      _updateFormation: "collaborateurs/updateFormation",
      _deleteFormation: "collaborateurs/deleteFormation",
    }),
    updateFormation: function (formation) {
      this.feedback = {};
      this.loading = true;
      this.formation.formation_montant_total_ark = this.calculMontantFormation;
      this._updateFormation(formation)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteFormation: function (formation) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null;
      this.feedback = {};
      this.loading = true;
      this._deleteFormation(formation)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    lib(id, data) {
      const index = data.findIndex((x) => x.id === id);
      return data[index].description;
    },
  },
};
</script>
<style lang="css"></style>
